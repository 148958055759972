import React from "react"
import { ButtonBase, Card, CardContent, Container, Grid, Typography } from "@mui/material"
import BadgeIcon from '@mui/icons-material/Badge';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useNavigate } from "react-router-dom"


const Home = () => {
  const navigate = useNavigate()
   return (
    <Container>
      <Grid container spacing={2} justifyContent="space-around">
        <Grid item xs={11} sm={6} md={4}>
          <Card>
            <ButtonBase
              onClick={() => navigate("/evaluation-request/create")}
              sx={{fontSize:"80px", justifyContent:"center", width:"100%"}}
            >
              <CardContent>
                <SummarizeIcon color="secondary" fontSize={"2.7rem"}/>
                <Typography fontSize={25}>تقديم طلب تقييم عقاري</Typography>
              </CardContent>
            </ButtonBase>
          </Card>
        </Grid>
        <Grid item xs={11} sm={6} md={4}>
          <Card>
            <ButtonBase
              sx={{fontSize:"80px", justifyContent:"center", width:"100%"}}
              onClick={() => navigate("/login")} 
            >
              <CardContent>
                <BadgeIcon color="secondary" fontSize="200"/>
                <Typography fontSize={25}>موظف</Typography>
              </CardContent>
            </ButtonBase>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Home