import React,  { useState }  from "react"
import { Box, Typography, Table, TableBody, TableRow, TableCell, Button, Stack, IconButton } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import { gql, useMutation } from "@apollo/client"

import DescriptionEdit from "./Edit/DescriptionEdit"


const UPDATE_EVALUATION = gql`
mutation($id: ID!, $input: ResidentialEvaluationUpdateInput!){
  updateResidentialEvaluation(id:$id, input:$input){
    evaluation{
      id
      descriptions{
        id
        title
        description
      }
      residentialevaluation{
        id
        location{
          id
          title
        }
        hasAlley
        hasWideAlley
        streetType{
          id
          title
        }
        curbSize
        isShared
        hasStreetLightPole
        hasBusStop
        isLand
        locationSpecs{
          id
          title
        }
        plotSpecs{
          id
          title
        }
        facing{
          id
          title
        }
        direction
        description
        floors
        buildingAge
        finishingQuality{
          id
          title
        }
        hasBasement
        apartments
        buildingFrontMaterial{
          id
          title
        }
      }
    }
  }
}
`


const Description = ({
  id, isEditable,
  floors, descriptions, isLand, hasAlley, 
  hasWideAlley, location, streetType, curbSize, 
  isShared, hasStreetLightPole, hasBusStop, 
  buildingAge, finishingQuality, buildingFrontMaterial, 
  direction, locationSpecs, plotSpecs, facing, hasBasement, apartments
}) => {

  const [edit, setEdit] = useState(false)
  const [description, setDescriptions] = useState(descriptions)
  const [values, setValues] = useState({
    floors: floors,
    location: location,
    streetType: streetType,
    locationSpecs: locationSpecs,
    plotSpecs:plotSpecs,
    facing: facing,
    hasAlley: hasAlley,
    hasWideAlley: hasWideAlley,
    isShared: isShared,
    hasStreetLightPole: hasStreetLightPole,
    hasBusStop: hasBusStop,
    isLand: isLand,
    direction: direction,
    curbSize: curbSize,
    buildingAge: buildingAge,
    finishingQuality: finishingQuality,
    hasBasement: hasBasement,
    apartments: apartments,
    buildingFrontMaterial: buildingFrontMaterial,
  })
  const [ updateEvaluation ] = useMutation(UPDATE_EVALUATION, {
    onCompleted: () => {
      setEdit(false)
    }
  })

  const handleSubmit = (e) => {
    let input = {...values}
    input.locationSpecs = values.locationSpecs.map(({id, title}) => ({id, title}))
    input.plotSpecs = values.plotSpecs.map(({id, title}) => ({id, title}))
    input.facing = values.facing.map(({id, title}) => ({id, title}))
    input.location =  {id: values.location.id, title: values.location.title}
    input.streetType = {id: values.streetType.id, title: values.streetType.title}
    input.finishingQuality = {id: values.finishingQuality.id, title: values.finishingQuality.title}
    input.buildingFrontMaterial = {id: values.buildingFrontMaterial.id, title: values.buildingFrontMaterial.title}
    updateEvaluation({variables:{id, input}})
  }

  const EditButton = (
    <IconButton size="small" onClick={()=>setEdit(true)}>
      <EditIcon />
    </IconButton>
  )

  const ActionButtons = (
    <Stack spacing={2} my={2} direction="row">
      <Button onClick={handleSubmit} variant="contained" >حفظ</Button>
      <Button onClick={() => setEdit(false)} size="small" >الغاء</Button>
    </Stack>
  )

  return (
    <Box mb={3}>
      <Typography variant="subtitle1" fontWeight={"bold"} gutterBottom>
        المواصفات الفنية للعقار
        {!edit && isEditable && EditButton}
      </Typography>
      {edit ? 
        <>
          <DescriptionEdit values={values} setValues={setValues} descriptions={description} setDescriptions={setDescriptions}/>
          {ActionButtons}
        </>
      :
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{width:"1%"}}><Typography noWrap>الموقع</Typography></TableCell>
            <TableCell>{location.title}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{width:"1%"}}><Typography noWrap>سكة</Typography></TableCell>
            <TableCell>{hasAlley ? hasWideAlley ? "سكة عريضة" : "يوجد سكة" : "لا يوجد سكة"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{width:"1%"}}><Typography noWrap>الشارع</Typography></TableCell>
            <TableCell>{streetType.title}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{width:"1%"}}><Typography noWrap>الارتداد</Typography></TableCell>
            <TableCell>{`الحجم ${curbSize}، `}{isShared && "مشترك"}{hasStreetLightPole && "، به عامود انارة"}{hasBusStop && "، به محطة باص"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{width:"1%"}}><Typography noWrap>حالة العقار</Typography></TableCell>
            <TableCell>{isLand ? "أرض" : "بناء قائم"}</TableCell>
          </TableRow>
          {!isLand && <>
            <TableRow>
              <TableCell sx={{width:"1%"}}><Typography noWrap>عمر البناء</Typography></TableCell>
              <TableCell>{buildingAge}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{width:"1%"}}><Typography noWrap>جودة التشطيب</Typography></TableCell>
              <TableCell>{finishingQuality.title}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{width:"1%"}}><Typography noWrap>عدد الادوار</Typography></TableCell>
              <TableCell>{`${floors} ${hasBasement&& "+ سرداب"}`}</TableCell>
            </TableRow>
            {apartments && <TableRow>
              <TableCell sx={{width:"1%"}}><Typography noWrap>عدد الشقق</Typography></TableCell>
              <TableCell>{apartments}</TableCell>
            </TableRow>}
            <TableRow>
              <TableCell sx={{width:"1%"}}><Typography noWrap>مادة واجهة البناء</Typography></TableCell>
              <TableCell>{buildingFrontMaterial.title}</TableCell>
            </TableRow>
          </>}
          <TableRow>
            <TableCell sx={{width:"1%"}}><Typography noWrap>اتجاه الواجهة</Typography></TableCell>
            <TableCell>{direction}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{width:"1%"}}><Typography noWrap>مواصفات الموقع</Typography></TableCell>
            <TableCell>{locationSpecs.map(({id, title}) => `${title}, `)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{width:"1%"}}><Typography noWrap>مواصفات القسيمة</Typography></TableCell>
            <TableCell>{plotSpecs.map(({id, title}) => `${title}, `)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{width:"1%"}}><Typography noWrap>العقار مقابل</Typography></TableCell>
            <TableCell>{facing.map(({id, title}) => `${title}, `)}</TableCell>
          </TableRow>
          {descriptions.map(({id, title, description}) =>
            <TableRow key={id}>
              <TableCell sx={{width:"1%"}}><Typography noWrap>{title}</Typography></TableCell>
              <TableCell>{description}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>}
    </Box>
  )
}

export default Description