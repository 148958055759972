import React, { useState } from "react"
import { Typography, Box, Button, Table, TableBody, TableRow, TableCell, IconButton, Stack } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import { gql, useMutation } from "@apollo/client"

import PriceEdit from "./Edit/PriceEdit";


const UPDATE_EVALUATION = gql`
mutation($id: ID!, $input: ResidentialEvaluationUpdateInput!){
  updateResidentialEvaluation(id:$id, input:$input){
    evaluation{
      id
      residentialevaluation{
        id
        estateValue,
        monthlyIncome
        landValue
        estateValueAfterUsage
      }
    }
  }
}
`


const Prices = (
  {
    id,
    isEditable,
    estateValue,
    monthlyIncome,
    landValue,
    estateValueAfterUsage,
  }) => {
  const [edit, setEdit] = useState(false)
  const [values, setValues] = useState({
    estateValue,
    monthlyIncome,
    landValue,
    estateValueAfterUsage,
  })
  const [ updateEvaluation ] = useMutation(UPDATE_EVALUATION, {
    onCompleted: () => {
      setEdit(false)
    }
  })

  const handleSubmit = (e) => {
		updateEvaluation({variables:{id, input: {...values}}})
	}

  const EditButton = (
      <IconButton size="small" onClick={()=>setEdit(true)}>
        <EditIcon/>
      </IconButton>
    )
    
  const ActionButtons = (
    <Stack spacing={2} my={2} direction="row">
      <Button onClick={handleSubmit} variant="contained" >حفظ</Button>
      <Button onClick={() => setEdit(false)} size="small" >الغاء</Button>
    </Stack>
  )

  return (
    <Box mt={2}> 
      <Typography variant="subtitle1" fontWeight={"bold"} gutterBottom>
        تقدير السعر النهائي
        {!edit && isEditable && EditButton}
      </Typography>
      { edit ?
        <>
          <PriceEdit values={values} setValues={setValues}/>
          {ActionButtons}
        </>
      :
        <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{width:"1%"}}><Typography noWrap>القيمة التقديرية للعقار</Typography></TableCell>
            <TableCell>{estateValue ? ` ${estateValue} د.ك. ` : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{width:"1%"}}><Typography noWrap>الايراد الشهري </Typography></TableCell>
            <TableCell>{monthlyIncome ? ` ${monthlyIncome} د.ك. ` : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{width:"1%"}}><Typography noWrap>قيمة البناء بعد خصم الاستهلاك</Typography></TableCell>
            <TableCell>{estateValueAfterUsage ? ` ${estateValueAfterUsage} د.ك. ` : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{width:"1%"}}><Typography noWrap>قيمة الارض</Typography></TableCell>
            <TableCell>{landValue ? ` ${landValue} د.ك. ` : "-"}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    }
    </Box>
  )
}

export default Prices