import React from "react"
import { Dialog, Typography, DialogContent, Box } from "@mui/material"


const SuccessfulCreation = ({handleClose, open, id}) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogContent>
        <Box p={2}>
          <Typography variant="h4" textAlign={"center"} gutterBottom>تم استلام طلبك بنجاح</Typography>
          <Typography variant="h6" textAlign={"center"}>رقم الطلب {id}</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default SuccessfulCreation