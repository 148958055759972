import React from "react"
import { Stack, TextField, Button, Grid } from "@mui/material"


const ImagesEdit = ({values, setValues}) => {

  const handleChange = ({target}) => {
    setValues({...values, [target.name]: target.files[0]})
  }
  
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Stack direction={"row"} spacing={2}>
          <TextField 
            placeholder="صورة العقار"
            value={values.estatePicture?.name || ""}
            size="small"
            required
            disabled
          />
          <input
            style={{ display: "none" }}
            id="estatePicture"
            type="file"
            name="estatePicture"
            onChange={handleChange}
            required
          />
          <label htmlFor="estatePicture">
            <Button variant="contained" color="primary" component="span">
              Upload
            </Button>
          </label>
        </Stack>
      </Grid>
      <Grid item>
        <Stack direction={"row"} spacing={2}>
          <TextField 
            placeholder="صورة موقع العقار"
            value={values.estateMap?.name || ""}
            size="small"
            required
            disabled
          />
          <input
            style={{ display: "none" }}
            id="estateMap"
            type="file"
            name="estateMap"
            onChange={handleChange}
            required
          />
          <label htmlFor="estateMap">
            <Button variant="contained" color="primary" component="span">
              Upload
            </Button>
          </label>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default ImagesEdit