import React from "react"
import { 
    Button, 
    Divider, 
    Stack, 
    TextField, 
    Grid, 
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Typography,
    ToggleButtonGroup,
    ToggleButton
 } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';

import { DIRECTIONS, DIRECTIONS_LIST } from "../../common/constants";
import { convertArabicNumbers, IsStringNumber } from "../../common/helpers";
import { AutoCompleteCheckboxField, AutoCompleteCreateField } from "../../common"


const EstateDescription = ({ setValues, values, descriptions, setDescription, locations, streetTypes, locationSpecs, plotSpecs, facings, qualities, materials}) => {
  
  const handlechange = ({target}) => {
  setValues({ ...values, [target.name]:target.value})
  }
  const handleCheckboxChange = ({target}) => {
    setValues({ ...values, [target.name]:target.checked})
  };
  const handleAutoCompletechange = (value, name) => {
    setValues({ ...values, [name]:value})
  }
  const handleNumberChange = ({target}) => {
    const value = convertArabicNumbers(target.value)
    setValues({ ...values, [target.name]:value})
  }

  const handleDescriptionChange = ({target}, index) => {
      let newDescriptions = [...descriptions]
      newDescriptions[index] = {...newDescriptions[index], [target.name]:target.value}
      setDescription(newDescriptions)
  }

  return (
    <Stack>
      <TextField 
        label="الوصف العام للعقار"
        name="description"
        value={values.description}
        onChange={handlechange}
        fullWidth
        multiline
        required
        rows={3}
      />
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={6} md={4}>
          <AutoCompleteCreateField label="الموقع" options={locations.map(({id, title})=>({id, title}))} value={values.location} name="location" handleChange={handleAutoCompletechange}/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AutoCompleteCreateField label="الشارع" options={streetTypes.map(({id, title})=>({id, title}))} value={values.streetType} name="streetType" handleChange={handleAutoCompletechange}/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField 
            label="حجم الارتداد"
            name="curbSize"
            value={values.curbSize}
            onChange={handlechange}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Divider sx={{ bgcolor: "goldenrod", marginY: "24px" }}/>
      <Stack direction={"row"}>
        <FormGroup>
          <FormControlLabel control={
            <Checkbox name="hasAlley" checked={values.hasAlley} onChange={handleCheckboxChange}/>
          } label="سكة" />
          <FormControlLabel control={
            <Checkbox name="hasWideAlley" checked={values.hasWideAlley} onChange={handleCheckboxChange}/>
          } label="سكة عريضة" />
        </FormGroup>
        <FormGroup>
          <FormControlLabel control={
              <Checkbox name="isShared" checked={values.isShared} onChange={handleCheckboxChange}/>
            } label="ارتداد مشترك" />
          <FormControlLabel control={
            <Checkbox name="hasStreetLightPole" checked={values.hasStreetLightPole} onChange={handleCheckboxChange}/>
          } label="به عامود إنارة" />
          <FormControlLabel control={
            <Checkbox name="hasBusStop" checked={values.hasBusStop} onChange={handleCheckboxChange}/>
          } label="به محطة باص" />
        </FormGroup>
      </Stack>
      <Divider sx={{ bgcolor: "goldenrod", marginY: "24px" }}/>
      <Typography variant="subtitle1" fontWeight={"bold"} gutterBottom> حالة العقار </Typography>
      <ToggleButtonGroup
        size="large"
        color="secondary"
        value={values.isLand}
        exclusive
        onChange={(e, value)=>setValues({...values, isLand:value})}
      >
        <ToggleButton value={true} >
          {"أرض"}
        </ToggleButton>
        <ToggleButton value={false}>
          {"بناء قائم"}
        </ToggleButton>
      </ToggleButtonGroup>
      {
        !values.isLand && (
        <>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField 
                label="عمر البناء "
                name="buildingAge"
                value={values.buildingAge}
                onChange={handlechange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AutoCompleteCreateField required label="جودة التشطيب" options={qualities.map(({id, title})=>({id, title}))} value={values.finishingQuality} name="finishingQuality" handleChange={handleAutoCompletechange}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AutoCompleteCreateField required label="مادة واجهة البناء " options={materials.map(({id, title})=>({id, title}))} value={values.buildingFrontMaterial} name="buildingFrontMaterial" handleChange={handleAutoCompletechange}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={6} sm={4}>
              <TextField 
                label="عدد الادوار"
                name="floors"
                error={!IsStringNumber(values.floors)}
                value={values.floors}
                onChange={handleNumberChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField 
                label="عدد الشقق"
                name="apartments"
                error={!IsStringNumber(values.apartments)}
                value={values.apartments}
                onChange={handleNumberChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControlLabel control={
                <Checkbox name="hasBasement" checked={values.hasBasement} onChange={handleCheckboxChange}/>
              } label="يوجد سرداب" />
            </Grid>
          </Grid>
        </>
        )
      }
      <Divider/>
      <Grid container spacing={2} my={1}>
        <Grid item xs={12} sm={6}>
          <AutoCompleteCheckboxField required label="مواصفات الموقع" name="locationSpecs" options={locationSpecs} value={values.locationSpecs} handleChange={handleAutoCompletechange}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoCompleteCheckboxField required label="مواصفات القسيمة" name="plotSpecs" options={plotSpecs} value={values.plotSpecs} handleChange={handleAutoCompletechange}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoCompleteCheckboxField required label="العقار مقابل" name="facing" options={facings} value={values.facing} handleChange={handleAutoCompletechange}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel id="direction">اتجاه الواجهة</InputLabel>
            <Select
              required
              labelId="direction"
              name="direction"
              value={values.direction}
              onChange={handlechange}
            >
              {
                DIRECTIONS_LIST.map(direction => <MenuItem key={direction} value={direction}>{DIRECTIONS[direction]}</MenuItem>)
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Divider/>
      <Button variant="outlined" onClick={()=> setDescription([...descriptions, {title:"", description:""}])}>إضافة</Button>
      {
          descriptions.map((description, index) => (
          <Grid key={index} container spacing={1} mt={1}>
              <Grid item sm={3} xs={4}>
                  <TextField
                    name="title"
                    value={description.title}
                    onChange={(e) => handleDescriptionChange(e, index)}
                    fullWidth
                    size="small"
                  />
              </Grid>
              <Grid item sm={9} xs={8}>
                  <TextField
                    name="description"
                    value={description.description}
                    onChange={(e) => handleDescriptionChange(e, index)}
                    fullWidth
                    size="small"
                  />
              </Grid>
              <Grid item>
                  <IconButton size="small" onClick={() => {const newDescriptions = [...descriptions]; newDescriptions.splice(index,1); setDescription(newDescriptions)}}>
                      <DeleteIcon/>
                  </IconButton>
              </Grid>
          </Grid>
          ))
      }
    </Stack>
  )
}

export default EstateDescription