import React, {useState} from "react"
import { gql, useMutation } from "@apollo/client";
import { Typography, Box, Stack, IconButton, Button, Grid } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';

import { GOVERNATES } from "../../common/constants"
import GeneralInfoEdit from "./Edit/GeneralInfoEdit";


const UPDATE_EVALUATION = gql`
mutation($id: ID!, $input: ResidentialEvaluationUpdateInput!){
  updateResidentialEvaluation(id:$id, input:$input){
    evaluation{
      id
      residentialevaluation{
        id
        governate
          area{
            id
            nameAr
          }
          block
          street
          avenue
          landArea
          landBlock
          documentNo
          planNo
          paciNo
      }
    }
  }
}
`


const GeneralInfo = ({isEditable, id, governate, area, block, street, avenue, landBlock, planNo, documentNo, paciNo, isLand, landArea}) => {
  const [edit, setEdit] = useState(false)
  const [values, setValues] = useState({
    governate,
    areaId: area,
    block,
    street,
    avenue,
    landBlock, 
    planNo, 
    documentNo, 
    paciNo, 
    isLand, 
    landArea
  })

  const [ updateEvaluation ] = useMutation(UPDATE_EVALUATION, {
    onCompleted: () => setEdit(false)
  })

  const handleSubmit = (e) => {
		updateEvaluation({variables:{id, input: {...values, areaId:values.areaId.id}}})
	}

  return (
  <>
    <Typography variant="subtitle1" fontWeight={"bold"} gutterBottom>معلومات عامة عن العقار 
      {!edit && isEditable &&
      <IconButton size="small" onClick={()=>setEdit(true)}>
        <EditIcon/>
      </IconButton>
      }
    </Typography>
    {edit ? 
      <>
        <GeneralInfoEdit values={values} setValues={setValues}/>
        <Stack spacing={2} my={2} direction="row">
          <Button onClick={handleSubmit} variant="contained" >حفظ</Button>
          <Button onClick={() => setEdit(false)} size="small" >الغاء</Button>
        </Stack>
        
      </>
      :
      <Box mb={3}>
        <Grid container spacing={3} flexWrap="wrap"> 
          <Grid item>
            <Typography noWrap fontWeight={"bold"}>اسم المحافظة</Typography>
            <Typography noWrap>{GOVERNATES[governate]}</Typography>
          </Grid>
          <Grid item>
            <Typography noWrap fontWeight={"bold"}>اسم المنطقة</Typography>
            <Typography noWrap>{area.nameAr}</Typography>
          </Grid>
          <Grid item>
            <Typography noWrap fontWeight={"bold"}>رقم القطعة</Typography>
            <Typography noWrap>{block}</Typography>
          </Grid>
          {street && 
          <Grid item>
            <Typography noWrap fontWeight={"bold"}>الشارع</Typography>
            <Typography noWrap>{street}</Typography>
          </Grid>}
          {avenue && 
          <Grid item>
            <Typography noWrap fontWeight={"bold"}>الجادة</Typography>
            <Typography noWrap>{avenue}</Typography>
          </Grid>}
          <Grid item>
            <Typography noWrap fontWeight={"bold"}>رقم القسيمة</Typography>
            <Typography noWrap>{landBlock}</Typography>
          </Grid>
          <Grid item>
            <Typography noWrap fontWeight={"bold"}>رقم الوثيقة</Typography>
            <Typography noWrap>{documentNo}</Typography>
          </Grid>
          <Grid item>
            <Typography noWrap fontWeight={"bold"}>رقم المخطط</Typography>
            <Typography noWrap>{planNo}</Typography>
          </Grid>
          <Grid item>
            <Typography noWrap fontWeight={"bold"}>الرقم الآلي</Typography>
            <Typography noWrap>{paciNo}</Typography>
          </Grid>
          <Grid item>
            <Typography noWrap fontWeight={"bold"}>المساحة م٢</Typography>
            <Typography noWrap>{landArea ? landArea : "-"}</Typography>
          </Grid>
          <Grid item>
            <Typography noWrap fontWeight={"bold"}>نوع العقار</Typography>
            <Typography noWrap>{isLand? "أرض" : "بناء قائم"}</Typography>
          </Grid>
        </Grid>
        </Box>
         }
    </>
  )
}

export default GeneralInfo