import React from "react"
import { useNavigate } from "react-router-dom";
import {Drawer, Box, List, ListItemText, ListItemIcon, ListItemButton, Divider} from "@mui/material"

// icons
import InboxIcon from '@mui/icons-material/Inbox';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import FiberNewIcon from '@mui/icons-material/FiberNew';

import useAuth from "../../authentication";


const SideNav = ({isOpen, setIsOpen}) => {
  const navigate = useNavigate()
  const { logout } = useAuth()

    return (
    <Drawer
      anchor={"right"}
      open={isOpen}
      onClose={()=>setIsOpen(false)}
    >
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={()=>setIsOpen(false)}
        onKeyDown={()=>setIsOpen(false)}
      >
        <List>
          <ListItemButton onClick={() => navigate("/")}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="التقييمات العقارية" />
          </ListItemButton>
          <Divider/>
          <ListItemButton onClick={() => navigate("/evaluation-request")}>
            <ListItemIcon>
              <FiberNewIcon />
            </ListItemIcon>
            <ListItemText primary="طلبات التقييم" />
          </ListItemButton>
          <Divider/>
          <ListItemButton onClick={logout}>
            <ListItemIcon>
              <LogoutIcon color="error" />
            </ListItemIcon>
            <ListItemText primary="تسجيل خروج" />
          </ListItemButton>
        </List>
      </Box>
    </Drawer>
    )
}

export default SideNav