import React from "react"
import moment from "moment"
import { Dialog, DialogTitle, DialogContent, Paper, Typography, Table, TableBody, TableCell, TableRow } from "@mui/material"


const SameEstateModal = ({paci, evaluations, open, handleClose}) => {
  
  if (!evaluations) return null

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>التقييمات العقارية للعقار بالرقم الآلي {paci}</DialogTitle>
      <DialogContent>
        {
          evaluations.length === 0 ? <Typography>لا يوجد  </Typography> :
          (
            <Table hover component={Paper}>
              <TableBody>
                {
                  evaluations.map(({id, createdDate, evaluator, isReadyForApproval, approvedDate}) => (
                    <TableRow key={id}>
                      <TableCell>{moment(createdDate).format("DD/MM/yyyy")}</TableCell>
                      <TableCell>{`${evaluator.firstName} ${evaluator.lastName}`}</TableCell>
                      <TableCell align="right">{approvedDate ? "مكتمل" : isReadyForApproval ? "جاهز للموافقة" : "غير مكتمل"}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          )
        }
      </DialogContent>
    </Dialog>
  )
}

export default SameEstateModal