import React, { useState } from "react"
import moment from "moment"
import { useNavigate, useParams } from "react-router-dom";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Paper, Typography, Button, Box, Container, Stepper, Step, StepButton } from "@mui/material"

import GeneralInfo from "./GeneralInfo";
import EstateDescription from "./EstateDescription";
import EstateValue from "./EstateValue";
import EstateInfo from "./EstateInfo";
import Documents from "./Documents";
import InternalOfficeInfo from "./InternalOfficeInfo";

import { Loading } from "../../common";
import { ESTATE_TYPES, ESTATE_TYPES_PERCENTAGE } from "../../common/constants"


const EVALUATION_DATA = gql`
	query{
		valuationMethods{
			id
			title
		}
		assetsMethods{
			id
			title
			isDefault
		}
		locations{
			id
			title
		}
		streetTypes{
			id
			title
		}
		locationSpecs{
			id
			title
		}
		plotSpecs{
			id
			title
		}
		facings{
			id
			title
		}
		qualities{
			id
			title
		}
		materials{
			id
			title
		}
	}
`


const CREATE_EVALUATION = gql`
	mutation(
		$input: ResidentialEvaluationInput!
	){
		createIndividualEvaluation(
			input: $input
		){
			evaluation{
				id
			}
		}
	}
`

const AREA = gql`
  query($governate: String){
    areas(governate:$governate){
      id
      nameAr
    }
  }
`


const Create = () => {
  const {evaluationRequestId} = useParams()
const type = "residential"
const estateType = ESTATE_TYPES[type]
  const percentage = ESTATE_TYPES_PERCENTAGE[type]
const navigate = useNavigate()
const [getAreas, {data:areas, loading: AreaLoading}] = useLazyQuery(AREA);
const [createEvaluation, { }] = useMutation(CREATE_EVALUATION, {onCompleted: (
	{createIndividualEvaluation: data}) => navigate(`/evaluation/${data.evaluation.id}`)});
const [values, setValues] = useState({
    date: moment().format("YYYY-MM-DD"),
    visitDate: moment().format("YYYY-MM-DD"),
    requestor: null,
    evaluationFor: null,
    regulatoryOpinion: `العقار المذكور أعلاه يخضع لنظام البناء ${estateType}، حيث يسمح بنسبة ${percentage} حسب النظم المتبعة في بلدية الكويت`,
		valuationMethodId: null,
		assetMethodId: null,
		governate : null,
    areaId: null,
    block:null,
		street:null,
		avenue:null,
    landArea: null,
    landBlock:null,
    documentNo: null,
    planNo:null,
		paciNo:null,

    floors: 0,
		location:null,
		streetType:null,
		locationSpecs:[],
		plotSpecs:[],
		facing:[],
		hasAlley:false,
		hasWideAlley:false,
		isShared:false,
		hasStreetLightPole:false,
		hasBusStop:false,
		isLand:true,
		direction:null,
		curbSize:null,
		buildingAge:null,
		finishingQuality:null,
		hasBasement:false,
		apartments:null,
		buildingFrontMaterial:null,
		
		estatePicture:null,
		estateMap: null,

		estateValue:null,
    monthlyIncome:null,
    landValue:null,
    estateValueAfterUsage:null,

		civilId: null,
		evaluationRequest: null,
		cost: null,
    evaluatorPercentage: null,
  })
	
	const [descriptions, setDescription] = useState([])
	const [activeStep, setActiveStep] = useState(0)
	const { loading, data } = useQuery(EVALUATION_DATA, {
		onCompleted: (data => 
			setValues({...values, valuationMethodId: data.valuationMethods[0].id})
			)
		})

	const handleSubmit = (e) => {
		const cleanedValues = Object.keys(values)
			.filter((k) => values[k] != null)
			.reduce((a, k) => ({ ...a, [k]: values[k] }), {})

		const input = {
			...cleanedValues,
			areaId: values.areaId.id,
			descriptions
		}
		let variables = {input}
    if (evaluationRequestId) variables.evaluationRequestId = evaluationRequestId
		createEvaluation({variables:{input}})
	}
	
	const getCompletionStatus = (index) => {
		if (index === 0){
			const {date, visitDate, requestor, evaluationFor, regulatoryOpinion, valuationMethodId, assetMethodId} = values
			return date && visitDate && requestor && evaluationFor && regulatoryOpinion && valuationMethodId && assetMethodId
		}
		else if (index === 1){
			return values.governate && values.areaId && values.block && values.paciNo && values.landArea && values.landBlock && values.documentNo && values.planNo
		} else if (index === 2){
			let value =  values.description && values.location && values.streetType && values.curbSize && values.direction
			if (!values.isLand)
				value = value && (values.buildingAge && values.finishingQuality && values.buildingFrontMaterial && values.floors !== null && values.apartments !== null)
			return value
		} else if (index === 3){
			return values.estatePicture && values.estateMap
		} else if (index === 4){
			return values.estateValue || values.monthlyIncome || values.landValue || values.estateValueAfterUsage
		} else if (index === 5){
			return values.cost !== null && values.evaluatorPercentage !== null && values.civilId && values.evaluationRequest 
		}
	}

	if (loading) return <Loading/>

	const formSections = [
		{
			title:"معلومات التقييم العقاري",
			details : <GeneralInfo {...data} values={values} setValues={setValues}/>
		},
		{
			title:"معلومات العقار",
			details : <EstateInfo type={type} values={values} setValues={setValues} areasInfo={{getAreas, data: areas, loading:AreaLoading}}/>
		},
		{
			title:"وصف العقار",
			details : <EstateDescription {...data} type={type} values={values} setValues={setValues} descriptions={descriptions} setDescription={setDescription}/>
		},
		{
			title:"المستندات المطلوبة",
			details : <Documents values={values} setValues={setValues}/>
		},
		{
			title:"قيمة العقار",
			details :  <EstateValue values={values} setValues={setValues}/>
		},
		{
			title:"معلومات خاصة للمكتب",
			details :  <InternalOfficeInfo values={values} setValues={setValues}/>
		}
	]

	return (
		<Container>
			<form onSubmit={(e)=>e.preventDefault()}>
				<Typography variant="h4" textAlign={"center"} mb={3}>
					تقييم عقاري {ESTATE_TYPES[type]} 
				</Typography>
				<Stepper activeStep={activeStep} nonLinear alternativeLabel>
					{formSections.map((section, index) => (
						<Step key={index} completed={getCompletionStatus(index)} sx={{
							"& .MuiStepLabel-iconContainer .Mui-completed ":{
								color: activeStep=== index ? "black" : "goldenrod"
							}
						}}>
							<StepButton color="inherit" onClick={() => setActiveStep(index)}>
								{section.title}
							</StepButton>
						</Step>
					))}
				</Stepper>
			
				<Paper component={Box} mx="auto" mt={3} mb={2} p={2}>
					
						{formSections[activeStep].details}

					
				</Paper>
				<Box display={"flex"} justifyContent="space-between" mb={5}>
					{activeStep !== 0 && <Button variant="contained" size="large" onClick={()=>setActiveStep(prev=>prev-1)}>السابق</Button>}
					{activeStep !== formSections.length-1 && <Button variant="contained" size="large" type="submit" onClick={()=>{if (getCompletionStatus(activeStep)) setActiveStep(prev=>prev+1)}}>التالي</Button>}
				</Box>
			
				{formSections.every((_, index) => getCompletionStatus(index)) && <Button variant="contained" type="submit" size="large" fullWidth onClick={handleSubmit} color="secondary">حفظ</Button>}
			</form>
		</Container>
		
	)
}

export default Create