export const GOVERNATES_LIST = [
    {
        title:"العاصمة",
        value:"AlAsimah"
    },
    {
        title:"الجهراء",
        value:"Jahra"
    },
    {
        title:"حولي",
        value:"Hawalli"
    },
    {
        title:"الفروانية",
        value:"Farwaniyah"
    },
    {
        title:"مبارك الكبير",
        value:"Mubarak Alkabeer"
    },
    {
        title:"الاحمدي",
        value:"Alahmadi"
    },
]

export const GOVERNATES = {
    AlAsimah: "العاصمة",
    Jahra: "الجهراء",
    Hawalli: "حولي",
    Farwaniyah: "الفروانية",
    "Mubarak Alkabeer": "مبارك الكبير",
    Alahmadi: "الاحمدي"
}

export const ESTATE_TYPES = {
    residential: "سكني",
    commercial: "استثماري",
    investment: "تجاري",
    industrial: "صناعي"
}

export const ESTATE_TYPES_PERCENTAGE = {
    residential: "210%",
    commercial: "250%",
    investment: "300%",
    industrial: "80%"
}


export const DIRECTIONS_LIST = ["North", "South", "East", "West"]

export const DIRECTIONS = {
    North: "شمال",
    South: "جنوب",
    East: "شرق",
    West: "غرب"
}