import React, { useState } from "react"
import { TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Grid, Stack, Button } from "@mui/material"
import { gql, useLazyQuery } from "@apollo/client"

import { GOVERNATES_LIST } from "../../common/constants"
import { convertArabicNumbers, IsStringNumber } from "../../common/helpers"
import SameEstateModal from "./SameEstateModal"

const AREA = gql`
  query($governate: String){
    areas(governate:$governate){
      id
      nameAr
    }
  }
`

const EVALUATIONS = gql`
query($search: String, $status: String, $areaId: ID){
  evaluations(search:$search, status:$status, areaId:$areaId){
    id
    createdDate
    evaluator{
      id
      firstName
      lastName
    }
    isPaid
    isReadyForApproval
    approvedDate
  }
}
`


const EstateInfo = ({ values, setValues, areasInfo}) => {
  const [open, setOpen] = useState(false)
  const [getEvaluations, {data: evaluationsdata, loading: evaluationsLoading}] = useLazyQuery(EVALUATIONS)
  const { getAreas, data, loading } = areasInfo

  const handleChange = ({target}) => {
    setValues({ ...values, [target.name]:target.value})
    if (target.name==="governate") {
      setValues({ ...values, areaId: null, [target.name]:target.value})
      getAreas({variables:{ governate: target.value }})
    }
  }

  const handleAreaChange = (event, areaId) => {
    if (areaId) setValues({ ...values, areaId})
  }

  const handleNumberChange = ({target}) => {
    const value = convertArabicNumbers(target.value)
    setValues({ ...values, [target.name]:value})
  }

  const fetchEvaluations = async () => {
    await getEvaluations({variables:{search: values.paciNo}}); 
    setOpen(true)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth required>
            <InputLabel id="governate">المحافظة</InputLabel>
            <Select
              labelId="governate"
              name="governate"
              value={values.governate}
              label="المحافظة"
              onChange={handleChange}
            >
              {
                GOVERNATES_LIST.map((governate, index) => <MenuItem key={index} value={governate.value}>{governate.title}</MenuItem>)
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Autocomplete
              options={data?.areas||[]}
              getOptionLabel={option => option.nameAr}
              renderInput={(params) => <TextField required {...params} label="المنطقة" />}
              loading={loading}
              fullWidth
              option
              required
              value={values.areaId}
              onChange={handleAreaChange}
              name="areaId"
            />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField 
            label="القطعة"
            name="block"
            value={values.block}
            onChange={handleNumberChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField 
            label="شارع"
            name="street"
            value={values.street}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField 
            label="جادة"
            name="avenue"
            value={values.avenue}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField 
            label="المساحة م٢"
            name="landArea"
            value={values.landArea}
            onChange={handleNumberChange}
            error={!IsStringNumber(values.landArea)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField 
            label="رقم القسيمة"
            name="landBlock"
            value={values.landBlock}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField 
            label="رقم الوثيقة"
            name="documentNo"
            value={values.documentNo}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField 
            label="رقم المخطط"
            name="planNo"
            value={values.planNo}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={10} lg={4}>
          <Stack direction={"row"} spacing={2}>
            <TextField 
              label="الرقم الآلي"
              name="paciNo"
              value={values.paciNo}
              onChange={handleChange}
              required
            />
            <Button disabled={evaluationsLoading} onClick={fetchEvaluations} variant="contained">{evaluationsLoading ? "يرجى الانتظار" :"أظهر التقييمات للعقار"}</Button>
          </Stack>
        </Grid>
      </Grid>
      <SameEstateModal open={open} handleClose={()=>setOpen(false)} paci={values.paciNo} evaluations={evaluationsdata?.evaluations}/>
    </>
  )
}

export default EstateInfo