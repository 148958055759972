import React from 'react';
import {TextField, Checkbox} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';


const filter = createFilterOptions();
const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export default function AutoCompleteCheckboxField({label, handleChange, options, name, required, initialValue}) {
  const [values, setValues] = React.useState(initialValue? initialValue:[])
  const setValue = (value) => {
      setValues(value)
      handleChange(value.map(({id, title})=>({id, title})), name)
  }

  return (
    <Autocomplete
      multiple
      fullWidth
      disableCloseOnSelect
      value={values}
      onChange={(event, newValue) => {
        if (newValue){
          let lastValue = newValue.pop()
          if (lastValue.inputValue) newValue.push({title: lastValue.inputValue})
          else newValue.push(lastValue)
        }
        setValue(newValue);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label={label} required={required && !values.length > 0} fullWidth/>
      )}
    />
  );
}
