import React, { useState } from "react"
import { Link } from "react-router-dom";
import {Box, AppBar, IconButton, Typography, Toolbar } from "@mui/material"

// icons
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SideNav from "./SideNav";


import logo from "../../static/images/cropped-logo.png"
import useAuth from "../../authentication";


const Nav = () => {
	const [isOpen, setIsOpen] = useState(false);
  const {isAuthenticated} = useAuth()

  return (
    <Box sx={{ flexGrow: 1 }} mb={8}>
			<AppBar position="static">
				<Toolbar>
          <img src={logo} height={"50px"} alt="logo"/>
          <Link to ="/" style={{textDecoration:"none", flexGrow: 1}}>
            <Typography fontWeight={"700"} variant="h6" component="div" color="white" ml={2}>
              عوائد الكويت
            </Typography>
          </Link>

		      {isAuthenticated && (
            <>
              <Link to="/profile">
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                >
                  <AccountCircle color="common"/>
                </IconButton>
              </Link>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={()=> setIsOpen(prev => !prev)}
              >
                <MenuIcon />
              </IconButton>
            </>
          )}
				</Toolbar>
			</AppBar>
			<SideNav isOpen={isOpen} setIsOpen={setIsOpen}/>
    </Box>
  )

}

export default Nav