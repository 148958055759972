import React from "react";
import { useMutation, gql } from "@apollo/client";
import {
  TextField,
  Button,
  Box,
  Paper,
  Typography,
  CircularProgress,
  Grid
} from "@mui/material";

import useAuth from "../../authentication";


const LOGIN = gql`
  mutation($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
    }
  }
`;


const Login = () => {
  const { login } = useAuth()

  const [loginUser, { called, loading, error }] = useMutation(LOGIN, {
    fetchPolicy: "no-cache",
    onCompleted: async data => {
      await login(data.tokenAuth);
    }
  });
  const handleSubmit = async e => {
    e.preventDefault();

    const data = new FormData(e.target);
    const input = {
      username: data.get("username"),
      password: data.get("password")
    };
    try {
      await loginUser({ variables: input });
    } catch (e) {}
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item lg={4} md={6} xs={11}>
        <Box p={3} mt={7} component={Paper} variant="outlined">
          <Typography variant="h5">تسجيل دخول</Typography>
          <form onSubmit={handleSubmit}>
            <Box
              name="username"
              label="اسم المستخدم"
              variant="outlined"
              size="small"
              required
              fullWidth
              component={TextField}
              mt={1}
              color="secondary"
            />
            <Box
              name="password"
              label="كلمة المرور"
              required
              type="password"
              variant="outlined"
              size="small"
              fullWidth
              component={TextField}
              my={1}
              color="secondary"
            />
            <Typography color="red">
              {error &&
                error.graphQLErrors.map(({ message }, i) => (
                  <span key={i}>{message}</span>
                ))}
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
            >
              {called && loading ? <CircularProgress size={20} /> : "تسجيل دخول"}
            </Button>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
