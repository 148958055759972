import React from "react"
import { Routes, Route } from "react-router-dom";

// componenets
import Create from "./Create";
import List from "./List";

const EvaluationRequest = () => {
  return (
    <Routes>
      <Route exact path={`/`} element={<List />}/>
      <Route exact path={`/create/`} element={<Create/>} />
    </Routes>
  );
}

export default EvaluationRequest