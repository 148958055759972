import React, {useState} from "react"
import moment from "moment"
import { TableRow, TableCell } from "@mui/material"

import Modal from "./Modal"


const Row = ({evaluationRequest}) => {
  const [open, setOpen] = useState(false)
  const status = 
  evaluationRequest.evaluation?.approvedDate ? "جاهز" : 
  evaluationRequest.evaluation?.isReadyForApproval ? "جاهز للموافقة" : 
  evaluationRequest.evaluation ? "التقييم العقاري قيد التنفيذ" :
  evaluationRequest.visitDate ? "تم زيارة العقار" :
  evaluationRequest.receivedDate ? "تم استلام الطلب من قبل المقيم" :
  "-"


  return (
    <>
    <TableRow hover onClick={() => setOpen(true)}>
      <TableCell>{evaluationRequest.id}</TableCell>
      <TableCell>{moment(evaluationRequest.createdDate).format("DD-MM-YYYY")}</TableCell>
      <TableCell>{evaluationRequest.phoneNumber}</TableCell>
      <TableCell>{status}</TableCell>
    </TableRow>
    <Modal open={open} {...evaluationRequest} handleClose={()=>setOpen(false)}/>
    </>
    
  )
}

export default Row