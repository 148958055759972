import React, { useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Stack, Button, MenuItem, TextField } from "@mui/material"

import useAuth from "../../../authentication"
import { Loading } from "../../common"


const EVALUATORS = gql`
query{
  evaluators{
    id
    firstName
    lastName
  }
}
`

const ASSIGN_EVALUATOR = gql`
mutation($id:ID!, $evaluator:ID!){
  assignEvaluationRequest(id:$id, evaluator:$evaluator){
    evaluationRequest{
      id
      assignedTo{
        id
        firstName
        lastName
      }
    }
  }
}`


const EvaluatorRequestActions = ({id, assignedTo}) => {
  const {user} = useAuth()
  const [evaluator, setEvaluator] = useState(null)
  const {data, loading} = useQuery(EVALUATORS, {skip: assignedTo || !user.isStaff})
  const [assignEvaluator] = useMutation(ASSIGN_EVALUATOR)

  if (loading) return <Loading/>

  const assigningEvaluator = () => (
    <Stack spacing={1}>
      <TextField
        label="المقيم"
        size="small"
        required
        labelId="evaluator"
        name="evaluator"
        select
        value={evaluator}
        onChange={({target}) => setEvaluator(target.value)}
      >
        {
          data.evaluators.map(evaluator => <MenuItem key={evaluator.id} value={evaluator.id}>{`${evaluator.firstName} ${evaluator.lastName}`}</MenuItem>)
        }
      </TextField>
      <Button variant="outlined" onClick={() => assignEvaluator({variables:{id, evaluator}})}>ارسال</Button>
    </Stack>
  )

  return (
    <>
      { user.isStaff && assignedTo === null && assigningEvaluator()}
    </>
  )

}

export default EvaluatorRequestActions