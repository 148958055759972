import React from "react";
import { Routes, Route } from "react-router-dom";

// componenets
import Create from "./Create";
import List from "./List";
import Details from "./Details";

const Evaluation = () => {
  return (
    <Routes>
      <Route exact path={`/`} element={<List />}/>
      <Route exact path={`/create/:type`} element={<Create/>} />
      <Route exact path={`/create/:type/request/:evaluationRequestId`} element={<Create/>} />
      <Route path={`/evaluation/:evaluationId`} element={<Details />}/>
    </Routes>
  );
};
export default Evaluation;
