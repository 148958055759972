import React from "react"
import { TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Grid } from "@mui/material"
import { gql, useQuery } from "@apollo/client"

import { GOVERNATES_LIST } from "../../../common/constants"
import { convertArabicNumbers,  IsStringNumber} from "../../../common/helpers";
import { Loading } from "../../../common";


const AREA = gql`
  query($governate: String){
    areas(governate:$governate){
      id
      nameAr
    }
  }
`


const GeneralInfoEdit = ({values, setValues}) => {
  const {data, loading, refetch} = useQuery(AREA, {variables:{governate:values.governate}});

  const handleChange = ({target}) => {
    setValues({ ...values, [target.name]:target.value})
    if (target.name==="governate") refetch({ governate: target.value })
  }

  const handleAreaChange = (event, areaId) => {
    if (areaId) setValues({ ...values, areaId})
  }

  const handleNumberChange = ({target}) => {
    const value = convertArabicNumbers(target.value)
    setValues({ ...values, [target.name]:value})
  }

  if (loading) return <Loading/>

    return (
      <Grid container spacing={2}>
        <Grid item md={6} sm={6} xs={12}>
          <FormControl fullWidth required>
            <InputLabel id="governate">المحافظة</InputLabel>
            <Select
              labelId="governate"
              name="governate"
              value={values.governate}
              label="المحافظة"
              onChange={handleChange}
            >
              {
                GOVERNATES_LIST.map(governate => <MenuItem key={governate.value} value={governate.value}>{governate.title}</MenuItem>)
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Autocomplete
            options={data?.areas||[]}
            getOptionLabel={option => option.nameAr}
            renderInput={(params) => <TextField required {...params} label="المنطقة" />}
            loading={loading}
            fullWidth
            option
            required
            value={values.areaId}
            onChange={handleAreaChange}
            name="areaId"
          />
        </Grid>
        <Grid item md={2} sm={4} xs={6}>
          <TextField 
              label="القطعة"
              name="block"
              value={values.block}
              onChange={handleNumberChange}
              fullWidth
              required
            />
        </Grid>
        <Grid item md={2} sm={4} xs={6}>
          <TextField 
            label="شارع"
            name="street"
            value={values.street}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item md={2} sm={4} xs={6}>
          <TextField 
            label="جادة"
            name="avenue"
            value={values.avenue}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item md={2} sm={4} xs={6}>
          <TextField 
            label="الرقم الآلي"
            name="paciNo"
            value={values.paciNo}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item md={2} sm={4} xs={6}>
          <TextField 
            label="المساحة م٢"
            name="landArea"
            value={values.landArea}
            onChange={handleNumberChange}
            error={!IsStringNumber(values.landArea)}
            fullWidth
            required
          />
        </Grid>
        <Grid item md={2} sm={4} xs={6}>
          <TextField 
            label="رقم القسيمة"
            name="landBlock"
            value={values.landBlock}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item md={2} sm={4} xs={6}>
          <TextField 
              label="رقم الوثيقة"
              name="documentNo"
              value={values.documentNo}
              onChange={handleChange}
              fullWidth
              required
            />
        </Grid>
        <Grid item md={2} sm={4} xs={6}>
          <TextField 
            label="رقم المخطط"
            name="planNo"
            value={values.planNo}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
      </Grid>
    )

}

export default GeneralInfoEdit