import React, { useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { Button, Typography, Box, Paper, Stack, TextField, Container } from "@mui/material"

import SuccessfulCreation from "./SuccessfulCreation"


const CREATE_EVALUATION_REQUEST = gql`
mutation($civilId: Upload!, $estateDocument: Upload!, $phoneNumber:String!){
  createEvaluationRequest(civilId:$civilId, estateDocument: $estateDocument, phoneNumber:$phoneNumber){
    evaluationRequest{
      id
    }
  }
}
`


const Create = () => {
  const [documents, setDocuments] = useState({
    civilId: null,
    estateDocument: null,
    phoneNumber:null
  })
  const [id, setId] = useState(null)
  const [open, setOpen] = useState(false)
  const [createRequest] = useMutation(CREATE_EVALUATION_REQUEST, {
    ignoreResults:false,
    onCompleted: (data) => {
      setDocuments({
        civilId: null,
        estateDocument: null,
        phoneNumber:null
      })
      setOpen(true)
      setId(data.createEvaluationRequest.evaluationRequest.id)

    }
   })

  const handleSubmit = async (e) => {
    e.preventDefault()
    await createRequest({variables:documents})
  }

  const handleChange = ({target}) => {
    if (target.name === "phoneNumber") setDocuments({...documents, [target.name]: target.value})
    setDocuments({...documents, [target.name]: target.files[0]})
  }

  return (
    <Container component={Box} m={2}>
      <form onSubmit={handleSubmit}>
        <Typography gutterBottom variant="h6">يرجى ارفاق المستندات التالية لطلب التقييم العقاري</Typography>
        <Paper component={Box} p={2} mb={2}>
            <Stack spacing={2}>
              <TextField
                value={documents.phoneNumber}
                name="phoneNumber"
                size="small"
                required
                type={"tel"}
                label="رقم التلفون"
                sx={{maxWidth:290}}
                onChange={handleChange}
              />
              <Stack direction={"row"} spacing={2}>
                <TextField 
                  placeholder="صورة البطاقة المدنية"
                  value={documents.civilId?.name || ""}
                  size="small"
                  required
                  disabled
                />
                <input
                  style={{ display: "none" }}
                  id="civilId"
                  type="file"
                  name="civilId"
                  onChange={handleChange}
                  required
                />
                <label htmlFor="civilId">
                  <Button variant="contained" color="primary" component="span">
                    Upload
                  </Button>
                </label>
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <TextField 
                  placeholder="صورة الوثيقة"
                  value={documents.estateDocument?.name || ""}
                  size="small"
                  required
                  disabled
                />
                <input
                  style={{ display: "none" }}
                  id="estateDocument"
                  type="file"
                  name="estateDocument"
                  onChange={handleChange}
                  required
                />
                <label htmlFor="estateDocument">
                  <Button variant="contained" color="primary" component="span">
                    Upload
                  </Button>
                </label>
              </Stack>
            </Stack>
        </Paper>
        <Button fullWidth size="large" variant="contained" color="secondary" type="submit">تقديم طلب التقييم</Button>
      </form>
      <SuccessfulCreation open={open} handleClose={()=>setOpen(false)} id={id}/>
    </Container>
  )

}

export default Create