import React, {useRef} from "react"
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from "@mui/material"
import SignatureCanvas from 'react-signature-canvas'
import { gql, useMutation } from "@apollo/client"


const APPROVE_EVALUATION = gql`
mutation($id:ID!, $signature: [SignaturePoint]!){
  approveEvaluation(id:$id, signature:$signature){
    evaluation{
      id
      approvedDate
      
    }
  }
}`


const ApprovalModal = ({id, open, handleClose}) => {
  const ref = useRef();
  const [approveEvaluation] = useMutation(APPROVE_EVALUATION);


  const handleClear = () => {
    ref.current.clear();
    handleClose()
  };

  const handleConfirm = () => {
    const signature =  ref.current.toData().map( stroke => 
        stroke.reduce( (partial, current) => {
          partial.x.push(current.x)
          partial.y.push(current.y)
          return partial
        }
        , {x:[], y: []})
      )
    approveEvaluation({variables:{id, signature}})
    handleClose()
  };


  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>يرجى التوقيع للموافقة على التقييم العقاري</DialogTitle>
      <DialogContent>
        <Box display={"flex"} width="fit-content" marginX={"auto"} border={1} borderColor="black">
          <SignatureCanvas 
          ref={ref} 
          canvasProps={{width: 200, height: 100}} />
        </Box>
        
      </DialogContent>
      
      <DialogActions>
        <Button onClick={handleClear}>إغلاق</Button>
        <Button disabled={ref.current===null} onClick={handleConfirm}>الموافقة و التوقيع على التقييم</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ApprovalModal