import React from "react"
import { gql, useLazyQuery } from "@apollo/client"
import { Button, TableCell, TableContainer, TableHead, TableRow, Table, TableBody, Paper, Container, Typography} from "@mui/material"

import { Loading } from "../../common"
import EvaluationRow from "./EvaluationRow"
import Filter from "./Filter"
import { useNavigate } from "react-router-dom"

const EVALUATIONS = gql`
query($search: String, $status: String, $areaId: ID){
  evaluations(search:$search, status:$status, areaId:$areaId){
    id
    isPaid
    isReadyForApproval
    approvedDate
    date
    governate
    area{
      id
      nameAr
    }
    block
  }
}
`

const List = () => {
  const navigate = useNavigate()
  const [ getEvaluations,  { data, loading }] = useLazyQuery(EVALUATIONS, {fetchPolicy:"network-only"})

  const evaluations = data?.evaluations ?? []
  const rows = evaluations.map((evaluation, index) => <EvaluationRow key={index} {...evaluation}/>)

  const headers = [
    {
      title:"",
      align: "left"
    },
    {
      title:"التاريخ",
      align: "left"
    },
    {
      title:"نوع العقار",
      align: "left"
    },
    {
      title:"الموقع",
      align: "left"
    },
    {
      title:"حالة الدفع",
      align: "right"
    },
    {
      title:"حالة الموافقة",
      align: "right"
    },

  ].map(({title, align}, index)=> <TableCell key={index} sx={{fontWeight:"bolder", backgroundColor: "#f5f5f5"}}  align={align} >{title}</TableCell>)

  return (
    <Container>
      <Button variant="contained" style={{float:"left"}} size="large" onClick={() => navigate("/create/residential")}>تقييم عقاري جديد</Button>
      <Filter getEvaluations={getEvaluations}/>
      {loading && <Loading/>}
      {evaluations.length ?<TableContainer component={Paper} sx={{ maxHeight: 800 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers} 
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
      :
      data && 
      <Paper width="100%">
        <Typography p={2} variant="h6" fontWeight={700} textAlign={"center"} >لا يوجد تقييمات</Typography>
      </Paper>
      }
    </Container>
    
  )
}

export default List