import React from "react"
import moment from "moment";
import { Stack, TextField } from "@mui/material"
import { DatePicker } from "@mui/lab";


const RequestInfoEdit = ({values, setValues}) => {
  const handlechange = ({target}) => {
    setValues({ ...values, [target.name]:target.value})
  }
  
  const handleDateChange = (date, name) => {
    setValues({...values, [name]: moment(date).format("YYYY-MM-DD")})
  }
  
  return (
      <>
        <Stack direction={"row"} justifyContent={"space-between"} marginTop={2} mx={1}>
        <TextField 
            label="طالب التقييم"
            name="requestor"
            value={values.requestor}
            onChange={handlechange}
            fullWidth
            required
        />
        <DatePicker
            label="التاريخ"
            inputFormat="DD/MM/yyyy"
            value={values.date}
            onChange={(date) => handleDateChange(date, "date")}
            renderInput={(params) => <TextField {...params} />}
            required
            fullWidth
        />
      </Stack>
    </>
  )
}

export default RequestInfoEdit