import React from "react"
import { TableContainer, Table, Paper, TableRow, TableHead, TableCell, TableBody } from "@mui/material"

import Row from "./Row"


const EvaluationRequestsTable = ({evaluationRequests}) => {
  const rows = evaluationRequests.map((evaluationRequest, index) => <Row key={index} evaluationRequest={evaluationRequest}/>)

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>رقم الطلب</TableCell>
            <TableCell>تاريخ الطلب</TableCell>
            <TableCell>رقم التلفون</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default EvaluationRequestsTable