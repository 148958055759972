import React from "react"
import { Box, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';


import theme from "./theme"

import useAuth from "./authentication";
import Nav from "./components/Nav"
import Login from "./components/Login"
import Evaluation from "./components/Evaluation";
import EvaluationRequestCreate from "./components/EvaluationRequest/Create";
import EvaluationRequest from "./components/EvaluationRequest";
import { Loading } from "./components/common"
import Profile from "./components/Profile";
import Home from "./components/Home";



const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

function App() {
  const { isAuthenticated, loading } = useAuth();

  if (loading) return <Loading />;

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}> 
        <Box mb={5}>
          <Nav/>
          {isAuthenticated ? (
              <Routes>
                <Route path="/profile" element={<Profile/>}/>
                <Route path="/evaluation-request/*" element={<EvaluationRequest/>} />
                <Route path="/*" element={<Evaluation/>} />
              </Routes>
            ) : (
              <Routes>
                <Route path="/evaluation-request/create" element={<EvaluationRequestCreate/>}/>
                <Route path="/" element={<Home/>}/>
                <Route path="/*" element={<Login/>} />
              </Routes>
            )}
        </Box>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
