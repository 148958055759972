import React from "react"
import { TextField, InputAdornment, Stack, Divider, Button, Grid } from "@mui/material"

import { convertArabicNumbers, IsStringNumber } from "../../common/helpers"


const InternalOfficeInfo = ({values, setValues}) => {

  const handleNumberChange = ({target}) => {
    const value = convertArabicNumbers(target.value)
    setValues({ ...values, [target.name]:value})
  }

  const handleChange = ({target}) => {
    setValues({...values, [target.name]: target.files[0]})
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="تكلفة التقييم"
          error={!IsStringNumber(values.cost)}
          name={"cost"}
          value={values.cost}
          onChange={handleNumberChange}
          fullWidth
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                د.ك.
              </InputAdornment>
          ),}}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField 
          label="نسبة المقيم" 
          name={"evaluatorPercentage"}
          value={values.evaluatorPercentage}
          onChange={handleNumberChange}
          error={!IsStringNumber(values.evaluatorPercentage)}
          fullWidth
          required
          InputProps={{
            endAdornment: (
                <InputAdornment position="start">
                  %
                </InputAdornment>
          ),}}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider/>
      </Grid>
      
      <Grid item xs={12} sm={6} md={4}>
        <Stack direction={"row"} spacing={2}>
          <TextField 
            placeholder="البطاقة المدنية"
            name="civilId"
            value={values.civilId?.name || null}
            disabled
            size="small"
          />
          <input
            style={{ display: "none" }}
            id="civilId"
            type="file"
            name="civilId"
            onChange={handleChange}
          />
          <label htmlFor="civilId">
            <Button variant="contained" color="primary" component="span">
              Upload
            </Button>
          </label>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Stack direction={"row"} spacing={2}>
          <TextField 
            placeholder="طلب التقييم"
            name="evaluationRequest"
            value={values.evaluationRequest?.name || ""}
            disabled
            size="small"
          />
          <input
            style={{ display: "none" }}
            id="evaluationRequest"
            type="file"
            name="evaluationRequest"
            onChange={handleChange}
          />
          <label htmlFor="evaluationRequest">
            <Button variant="contained" color="primary" component="span">
              Upload
            </Button>
          </label>
        </Stack>
      </Grid>
    
      
        
    </Grid>
  )
}

export default InternalOfficeInfo