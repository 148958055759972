import React from "react"
import { gql, useQuery } from "@apollo/client"
import { Container, Typography } from "@mui/material"

import { Loading } from "../../common"
import EvaluationRequestsTable from "./EvaluationRequestsTable"


const EVALUATION_REQUESTS = gql`
query($unassigned:Boolean){
  evaluationRequests(unassigned:$unassigned){
    id
    civilId
    estateDocument
    createdDate
    receivedDate
    visitDate
    assignedTo{
      id
      firstName
      lastName
    }
    phoneNumber
    evaluation{
      id
      approvedDate
      isReadyForApproval
    }
  }
}
`


const List = () => {
  const {data, loading} = useQuery(EVALUATION_REQUESTS, {variables:{unassigned:false}})

  if (loading) return <Loading/>

  const {evaluationRequests} = data

  return (
    <Container>
      <Typography variant="h4" textAlign={"center"} mb={3}>
					طلبات التقييم العقاري
			</Typography>
      <EvaluationRequestsTable evaluationRequests={evaluationRequests}/>
    </Container>
  )
}

export default List