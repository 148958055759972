import { createTheme } from "@mui/material/styles";

export default createTheme({
    direction: 'rtl',
    palette: {
        common:{
            main :"#ffffff",
        },
        primary: {
            contrastText: "#ffffff",
            dark: "#000000" ,
            main: "#2F4F4F",
            light: "#2F4F4F"
        },
        secondary:{
            contrastText: "#ffffff",
            dark: "#D4AF37" ,
            main: "#D4AF37",
            light: "#D4AF37"
        }
      },
  });