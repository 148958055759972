import React from "react"
import { gql, useQuery } from "@apollo/client";
import { 
    Button, 
    Divider, 
    TextField, 
    Grid, 
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Typography,
    ToggleButtonGroup,
    ToggleButton
 } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';

import { AutoCompleteCreateField, AutoCompleteCheckboxField, Loading } from "../../../common";
import { DIRECTIONS, DIRECTIONS_LIST } from "../../../common/constants";
import { convertArabicNumbers, IsStringNumber } from "../../../common/helpers";


const EVALUATION_DATA = gql`
	query{
		locations{
			id
			title
		}
		streetTypes{
			id
			title
		}
		locationSpecs{
			id
			title
		}
		plotSpecs{
			id
			title
		}
		facings{
			id
			title
		}
		qualities{
			id
			title
		}
		materials{
			id
			title
		}
	}
`


const DescriptionEdit = ({ setValues, values, descriptions, setDescription}) => {
  const { loading, data } = useQuery(EVALUATION_DATA)

  if (loading) return <Loading/>

  const {locations, streetTypes, locationSpecs, plotSpecs, facings, qualities, materials} = data

  const handlechange = ({target}) => {
  setValues({ ...values, [target.name]:target.value})
  }
  const handleCheckboxChange = ({target}) => {
    setValues({ ...values, [target.name]:target.checked})
  };
  const handleAutoCompletechange = (value, name) => {
    setValues({ ...values, [name]:value})
  }
  const handleNumberChange = ({target}) => {
    const value = convertArabicNumbers(target.value)
    setValues({ ...values, [target.name]:value})
  }

  const handleDescriptionChange = ({target}, index) => {
      let newDescriptions = [...descriptions]
      newDescriptions[index] = {...newDescriptions[index], [target.name]:target.value}
      setDescription(newDescriptions)
  }

  return (
    <>
    <Grid container spacing={2}>
      <Grid item md={4} sm={6} xs={12}>
        <AutoCompleteCreateField label="الموقع" options={locations.map(({id, title})=>({id, title}))} value={values.location} name="location" handleChange={handleAutoCompletechange}/>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <AutoCompleteCreateField label="الشارع" options={streetTypes.map(({id, title})=>({id, title}))} value={values.streetType} name="streetType" handleChange={handleAutoCompletechange}/>
      </Grid>
      <Grid item md={4} xs={12}>
        <FormGroup>
            <FormControlLabel control={
              <Checkbox name="hasAlley" checked={values.hasAlley} onChange={handleCheckboxChange}/>
            } label="سكة" />
            <FormControlLabel control={
              <Checkbox name="hasWideAlley" checked={values.hasWideAlley} onChange={handleCheckboxChange}/>
            } label="سكة عريضة" />
          </FormGroup>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <TextField 
            label="حجم الارتداد"
            name="curbSize"
            value={values.curbSize}
            onChange={handlechange}
            fullWidth
            required
          />
      </Grid>
      <Grid item xs={6}>
        <FormGroup>
          <FormControlLabel control={
              <Checkbox name="isShared" checked={values.isShared} onChange={handleCheckboxChange}/>
            } label="ارتداد مشترك" />
          <FormControlLabel control={
            <Checkbox name="hasStreetLightPole" checked={values.hasStreetLightPole} onChange={handleCheckboxChange}/>
          } label="به عامود إنارة" />
          <FormControlLabel control={
            <Checkbox name="hasBusStop" checked={values.hasBusStop} onChange={handleCheckboxChange}/>
          } label="به محطة باص" />
        </FormGroup>
      </Grid>
      
      <Grid item xs={12}>
        <Divider sx={{ bgcolor: "goldenrod", marginY: "8px" }}/>
        <Typography variant="subtitle1" fontWeight={"bold"} gutterBottom> حالة العقار </Typography>
        <ToggleButtonGroup
          size="large"
          color="secondary"
          value={values.isLand}
          exclusive
          onChange={(e, value)=>setValues({...values, isLand:value})}
        >
          <ToggleButton value={true} >
            {"أرض"}
          </ToggleButton>
          <ToggleButton value={false}>
            {"بناء قائم"}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
      {
        !values.isLand && (
        <>
        <Grid container spacing={2} mt={1}>
          <Grid item md={3} sm={4} xs={6}>
            <TextField 
              label="عمر البناء "
              name="buildingAge"
              value={values.buildingAge}
              onChange={handlechange}
              fullWidth
              required
            />
          </Grid>
          <Grid item md={3} sm={4} xs={6}>
            <AutoCompleteCreateField required label="جودة التشطيب" options={qualities.map(({id, title})=>({id, title}))} value={values.finishingQuality} name="finishingQuality" handleChange={handleAutoCompletechange}/>
          </Grid>
          <Grid item md={3} sm={8} xs={12}>
            <AutoCompleteCreateField required label="مادة واجهة البناء " options={materials.map(({id, title})=>({id, title}))} value={values.buildingFrontMaterial} name="buildingFrontMaterial" handleChange={handleAutoCompletechange}/>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
            <Grid item md={3} sm={4} xs={6}>
              <TextField 
                  label="عدد الادوار"
                  name="floors"
                  error={!IsStringNumber(values.floors)}
                  value={values.floors}
                  onChange={handleNumberChange}
                  fullWidth
                  required
                />
            </Grid>
            <Grid item md={3} sm={4} xs={6}>
              <TextField 
                label="عدد الشقق"
                name="apartments"
                error={!IsStringNumber(values.apartments)}
                value={values.apartments}
                onChange={handleNumberChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item md={3} sm={4} xs={6}>
              <FormControlLabel control={
                  <Checkbox name="hasBasement" checked={values.hasBasement} onChange={handleCheckboxChange}/>
                } label="يوجد سرداب" />
            </Grid>
          </Grid>      
      
        </>
        )
      }
      <Divider sx={{  marginY: "20px" }}/>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <AutoCompleteCheckboxField required label="مواصفات الموقع" name="locationSpecs" options={locationSpecs} value={values.locationSpecs} initialValue={values.locationSpecs} handleChange={handleAutoCompletechange}/>
        </Grid>
        <Grid item md={6} xs={12}>
          <AutoCompleteCheckboxField required label="مواصفات القسيمة" name="plotSpecs" options={plotSpecs} value={values.plotSpecs} initialValue={values.plotSpecs} handleChange={handleAutoCompletechange}/>
        </Grid>
        <Grid item md={6} xs={12}>
          <AutoCompleteCheckboxField required label="العقار مقابل" name="facing" options={facings} value={values.facing} initialValue={values.facing}  handleChange={handleAutoCompletechange}/>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth required>
            <InputLabel id="direction">اتجاه الواجهة</InputLabel>
            <Select
              required
              labelId="direction"
              name="direction"
              value={values.direction}
              onChange={handlechange}
            >
              {
                DIRECTIONS_LIST.map(direction => <MenuItem key={direction} value={direction}>{DIRECTIONS[direction]}</MenuItem>)
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Divider sx={{  marginY: "20px" }}/>
      <Button variant="outlined" onClick={()=> setDescription([...descriptions, {title:"", description:""}])}>إضافة</Button>
      {
          descriptions.map((description, index) => (
          <Grid key={index} container spacing={1} marginTop={1}>
              <Grid item xs={3}>
                  <TextField
                    name="title"
                    value={description.title}
                    onChange={(e) => handleDescriptionChange(e, index)}
                    fullWidth
                    size="small"
                  />
              </Grid>
              <Grid item xs={8}>
                  <TextField
                    name="description"
                    value={description.description}
                    onChange={(e) => handleDescriptionChange(e, index)}
                    fullWidth
                    size="small"
                  />
              </Grid>
              <Grid item>
                  <IconButton size="small" onClick={() => {const newDescriptions = [...descriptions]; newDescriptions.splice(index,1); setDescription(newDescriptions)}}>
                      <DeleteIcon/>
                  </IconButton>
              </Grid>
          </Grid>
          ))
      }

  </>
  )
}

export default DescriptionEdit