import React, { useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { IconButton, Stack, Typography, Button, Grid } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';

import RequestInfoEdit from "./Edit/RequestInfoEdit";


const UPDATE_EVALUATION = gql`
mutation($id: ID!, $input: ResidentialEvaluationUpdateInput!){
  updateResidentialEvaluation(id:$id, input:$input){
    evaluation{
      id
      date
      requestor
    }
  }
}
`


const RequestInfo = ({id, requestor, date, isEditable}) => {
  const [edit, setEdit] = useState(false)
  const [values, setValues] = useState({
    requestor,
    date
  })

  const [ updateEvaluation ] = useMutation(UPDATE_EVALUATION, {
    onCompleted: () => setEdit(false)
  })

  const handleSubmit = (e) => {
		updateEvaluation({variables:{id, input: {...values}}})
	}
  
  return (
    edit ? (
    <>
      <RequestInfoEdit values={values} setValues={setValues} />
      <Stack spacing={2} my={2} direction="row">
        <Button onClick={handleSubmit} variant="contained">حفظ</Button>
        <Button onClick={() => setEdit(false)}>الغاء</Button>
      </Stack>
    </>)
    :(
      <Grid container  justifyContent="space-between" mt={2}>
        <Grid item>
          <Typography variant="h6">
            السادة {requestor} المحترمين
            {!edit && isEditable &&
            <IconButton size="small" onClick={()=>setEdit(true)}>
              <EditIcon/>
            </IconButton>
            }
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">
            {date}
            {!edit && isEditable && <IconButton size="small" onClick={()=>setEdit(true)}>
              <EditIcon/>
            </IconButton>}
          </Typography>
        </Grid>
      </Grid>
    )
  )
}

export default RequestInfo