import React, { useState } from "react"

import { Search } from "@mui/icons-material"
import { TextField, Grid, InputAdornment, MenuItem, Button } from "@mui/material"


const evaluationStatus = [
  {
    value:null,
    label:"الكل"
  },
  {
    value:"incomplete",
    label:"غير مكتمل"
  },
  {
    value:"ready for approval",
    label:"جاهز للموافقة"
  },
  {
    value:"completed",
    label:"مكتمل"
  },
]

const Filter = ({getEvaluations}) => {
  const [values, setValues] = useState({
    search:null,
    status: null
  })

  const handleChange = ({target}) => {
    const {name, value} = target
    setValues({...values, [name]:value})
  }

  const handleSearch = () => {
    getEvaluations({variables: values})
  }

  return (
  <form onSubmit={(e) => {e.preventDefault(); handleSearch();}}>
    <Grid spacing={2} container mb={7}>
      <Grid item xs="12" sm="5" md={4}>
        <TextField
          InputProps={{
            startAdornment: <InputAdornment position="start"><Search/></InputAdornment>,
          }}
          placeholder="بحث"
          size="small"
          margin="normal"
          fullWidth
          name="search"
          value={values.search}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs="12" sm="3" md="2">
        <TextField
          select
          label="حالة التقييم"
          size="small"
          margin="normal"
          fullWidth
          name="status"
          value={values.status}
          onChange={handleChange}
        >
          {evaluationStatus.map(({value, label}) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth variant="contained" onClick={handleSearch} type="submit">بحث</Button>
      </Grid>
    </Grid>
  </form>
  )

}

export default Filter