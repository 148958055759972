import React, { useState } from "react"
import moment from "moment"
import { gql, useMutation } from "@apollo/client"
import { Typography, Box, Divider, Button, Stack } from "@mui/material"

import useAuth from "../../../authentication"
import ApprovalModal from "./ApprovalModal"


const UPDATE_EVALUATION_PAYMENT = gql`
mutation($id:ID!, $isPaid: Boolean, $isReadyForApproval: Boolean, $approved:Boolean, $isEvaluatorPaid:Boolean){
  updateEvaluationFinancialData(id:$id, isPaid:$isPaid, isReadyForApproval: $isReadyForApproval, approved:$approved, isEvaluatorPaid: $isEvaluatorPaid){
    evaluation{
      id
      isPaid
      isReadyForApproval
      isEvaluatorPaid
      approvedDate
      paymentDate
    }
  }
}
`

const UPDATE_EVALUATION = gql`
mutation($id: ID!, $input: ResidentialEvaluationUpdateInput!){
  updateResidentialEvaluation(id:$id, input:$input){
    evaluation{
      id
      residentialevaluation{
        id
        civilId
        evaluationRequest
      }
    }
  }
}`


const InternalOfficerInfo = ({id, evaluator, visitDate, cost, evaluatorPercentage, isPaid, isEvaluatorPaid, approvedDate, isReadyForApproval, civilId, evaluationRequest}) => {
  const {user} = useAuth()
  const [open, setOpen] = useState(false)
  const [updateEvaluationFinancialData] = useMutation(UPDATE_EVALUATION_PAYMENT)
  const [ updateEvaluation ] = useMutation(UPDATE_EVALUATION)

  const setEvaluationStatus = (name) => {
    updateEvaluationFinancialData({variables: {id ,[name]:true}})
  }

  const handleSubmit = ({target}) => {
		updateEvaluation({variables:{id, input: {[target.name]:target.files[0]}}})
	}

  return (
  <Box>
    <Box display="flex" justifyContent="space-between">
      <Typography variant="h6" gutterBottom>{`المقيم ${evaluator.firstName} ${evaluator.lastName}`}</Typography>
      <Typography variant="h6" gutterBottom>{`تاريخ الزيارة ${moment(visitDate).format("DD-MM-YYYY")}`}</Typography>
    </Box>
    
    <Divider sx={{ bgcolor: "goldenrod", marginY: "24px" }}/>
    <Stack direction="row" spacing={3}>
      <Typography fontWeight={"bold"} >{`قيمة التقييم ${cost} د.ك.`}</Typography>
      <Button disabled={isPaid} variant="outlined" size="small" onClick={() => setEvaluationStatus("isPaid")}>{isPaid ? "مدفوع" : "تم الدفع"}</Button>
    </Stack>
    <Stack mt={2} direction="row" spacing={3}>
      <Typography><b>{`نسبة المقيم ${evaluatorPercentage}%`}</b>  {`(${evaluatorPercentage*cost/100} د.ك.)`}</Typography>
      <Button disabled={isEvaluatorPaid} variant="outlined" size="small" onClick={() => setEvaluationStatus("isEvaluatorPaid")}>{isEvaluatorPaid ? "مدفوع" : "تم الدفع"}</Button>
    </Stack>
    <Divider sx={{ bgcolor: "goldenrod", marginY: "24px" }}/>
    <Typography variant="subtitle1" fontWeight={"bold"} gutterBottom>  المستندات المرفوقة </Typography>
    <Stack direction={"row"} spacing={1}>
      <Typography variant="subtitle1" gutterBottom>  البطاقة المدنية: {civilId ? <a href={civilId} target="_blank" rel="noreferrer">مشاهدة</a> : "لا يوجد"} </Typography>
      <input
        style={{ display: "none" }}
        id="civilId"
        type="file"
        name="civilId"
        onChange={handleSubmit}
      />
      <label htmlFor="civilId">
        <Button variant="outlined" size="small" component="span">تغيير</Button>
      </label>
    </Stack>
    <Stack direction={"row"} spacing={1} mt={1}>
      <Typography variant="subtitle1"  gutterBottom>  طلب التقييم: {evaluationRequest ? <a href={evaluationRequest} target="_blank" rel="noreferrer">مشاهدة</a> : "لا يوجد"} </Typography>
      <input
        style={{ display: "none" }}
        id="evaluationRequest"
        type="file"
        name="evaluationRequest"
        onChange={handleSubmit}
      />
      <label htmlFor="evaluationRequest">
        <Button variant="outlined" size="small" component="span">تغيير</Button>
      </label>
    </Stack>
    
    
    <Divider sx={{ bgcolor: "goldenrod", marginY: "24px" }}/>
    <Typography variant="subtitle1" fontWeight={"bold"} gutterBottom> حالة الموافقة </Typography>
    <Typography variant="subtitle1"> {approvedDate ? "تمت الموافقة" : isReadyForApproval ? "جاهز للموافقة" : "التقييم ما زال غير مكتمل"} </Typography>
    <Divider sx={{ bgcolor: "goldenrod", marginY: "24px" }}/>
    {!isReadyForApproval && approvedDate === null &&
      <Button variant="contained" onClick={() => setEvaluationStatus("isReadyForApproval")}>
        التقييم جاهز للموافقة
      </Button>
    }
    {isReadyForApproval && ! approvedDate && user.isStaff && 
      <Button variant="contained" onClick={() => setOpen(true)}>
        الموافقة
      </Button>
    }
    <ApprovalModal id={id} open={open} handleClose={()=>setOpen(false)}/>
  </Box>
  )
}

export default InternalOfficerInfo