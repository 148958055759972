import React from "react"
import moment from "moment"
import { Step, StepLabel, Dialog, DialogTitle, DialogContent, DialogActions, Button, Stepper, Stack, Typography, Divider } from "@mui/material"

import useAuth from "../../../authentication"
import EvaluatorRequestActions from "./EvaluatorRequestActions"
import { gql, useMutation } from "@apollo/client"
import { useNavigate } from "react-router-dom"


const UPDATE_EVALUATION_REQUEST = gql`
mutation($id:ID!, $visited:Boolean, $received:Boolean){
  updateEvaluationRequest(id:$id, visited:$visited, received:$received){
    evaluationRequest{
      id
      receivedDate
      visitDate
    }
  }
}
`

const Modal = ({id, open, handleClose, civilId, estateDocument, assignedTo, receivedDate, visitDate, evaluation}) => {
  const navigate = useNavigate()
  const [ updateEvaluationRequest ] = useMutation(UPDATE_EVALUATION_REQUEST);
  const {user} = useAuth()
  const steps = [
    {
      label: "تم تعيين الطلب",
      optional: assignedTo && `${assignedTo.firstName} ${assignedTo.lastName}`
    },
    {
      label: "تم استلام الطلب ",
      optional: receivedDate && moment(receivedDate).format("DD-MM-YYYY") ,
    },
    {
      label:  "تم زيارة العقار",
      optional: visitDate && moment(visitDate).format("DD-MM-YYYY")
    },
    {
      label: "انتظار الموافقة"
    },
    {
      label: "مكتمل",
      optional: evaluation?.approvedDate && moment(evaluation?.approvedDate).format("DD-MM-YYYY")
    }]
  
    const stepLocation = 
      evaluation?.approvedDate ? 4 :
      evaluation?.isReadyForApproval ? 3 :
      visitDate ? 2 :
      receivedDate ? 1 : 0

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>طلب تقييم {id} </DialogTitle>
      <DialogContent>
        <Stack spacing={2} direction="row" mb={2} justifyContent="space-between">
          <Typography>الوثيقة</Typography>
          <Button href={estateDocument} target="_blank" rel="noreferrer" variant="contained" size="small" color="secondary">تحميل</Button>
        </Stack>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Typography>البطاقة المدنية</Typography>
          <Button href={civilId} target="_blank" rel="noreferrer" variant="contained" size="small" color="secondary">تحميل</Button>
        </Stack>
        <Divider sx={{ bgcolor: "goldenrod", marginY: "24px" }}/>
        {assignedTo && 
          <Stack direction={"row"} divider={<Divider orientation="vertical"/>} spacing={3}>  
            <Stepper activeStep={stepLocation} orientation="vertical">
              {steps.map(({label, optional}) => (
                <Step key={label} >
                  <StepLabel optional={<Typography variant="caption">{optional}</Typography>}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Stack spacing={3} width="50%" alignSelf={"center"}>
            { !user.isStaff &&(
                receivedDate === null ? <Button fullWidth variant="contained" onClick={()=>updateEvaluationRequest({variables:{id, received:true}})}>تم استلام الطلب</Button> :
                visitDate === null ? <Button fullWidth variant="contained" onClick={()=>updateEvaluationRequest({variables:{id, visited:true}})}>تم زيارة العقار</Button> :
                evaluation === null ? <Button fullWidth variant="contained" onClick={()=>navigate(`/create/residential/request/${id}`)}>إنشاء التقييم العقاري</Button> :
                evaluation.isReadyForApproval === false ? <Button fullWidth variant="contained">جاهز للموافقة</Button> :
                null)
              }
            
              <span style={{flexGrow:1}}/>
              {evaluation && <Button fullWidth variant="contained" >تفاصيل التقييم العقاري</Button>}
            </Stack>
          </Stack>
        }
       
        
        <EvaluatorRequestActions id={id} assignedTo={assignedTo}/>
      </DialogContent>
      
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )

}

export default Modal