import React from "react"
import moment from "moment"
import { TextField, Stack, Divider, FormLabel, RadioGroup, Radio, FormControlLabel, FormControl } from "@mui/material"
import { DatePicker } from "@mui/lab"


const GeneralInfo = ({valuationMethods, assetsMethods, values, setValues}) => {
  const handlechange = ({target}) => {
    setValues({ ...values, [target.name]:target.value})
  }
  
  const handleDateChange = (date, name) => {
    setValues({...values, [name]: moment(date).format("YYYY-MM-DD")})
  }

  return (
  <Stack spacing={2}>
    <Stack direction={"row"} spacing={2}>
      <DatePicker
        label="التاريخ"
        inputFormat="DD/MM/yyyy"
        value={values.date}
        onChange={(date) => handleDateChange(date, "date")}
        renderInput={(params) => <TextField {...params} />}
        required
        fullWidth
      />
      <DatePicker
        label="تاريخ الزيارة"
        inputFormat="DD/MM/yyyy"
        value={values.visitDate}
        onChange={(date) => handleDateChange(date, "visitDate")}
        renderInput={(params) => <TextField {...params} />}
        required
        fullWidth
      />
    </Stack>
   
    <Stack direction={"row"} spacing={2}>
      <TextField 
        label="طالب التقييم"
        name="requestor"
        value={values.requestor}
        onChange={handlechange}
        fullWidth
        required
      />
      <TextField 
        label="موجهة ل"
        name="evaluationFor"
        value={values.evaluationFor}
        onChange={handlechange}
        fullWidth
        required
      />
    </Stack>
    <Divider/>
    <TextField
      label="الرأي التنظيمي"
      value={values.regulatoryOpinion}
      onChange={handlechange}
      name="regulatoryOpinion"
      required
    />
    <FormControl>
      <FormLabel id="valuationMethodId">طريقة التقييم</FormLabel>
      <RadioGroup
        row
        aria-labelledby="valuationMethodId"
        name="valuationMethodId"
        value={values.valuationMethodId}
        onChange={handlechange}
        required
      >
        {valuationMethods.map(method => <FormControlLabel key={method.id} value={method.id} control={<Radio  color="secondary"/>} label={method.title} />)}
      </RadioGroup>
    </FormControl>
    <FormControl>
      <FormLabel id="assetMethodId">طريقة الأصول و الالتزامات التي يتم تقييمها</FormLabel>
      <RadioGroup
        row
        name="assetMethodId"
        value={values.assetMethodId}
        onChange={handlechange}
        required
      >
        {assetsMethods.map(method => <FormControlLabel  key={method.id} value={method.id} control={<Radio  color="secondary"/>} label={method.title} />)}
      </RadioGroup>
    </FormControl>
  </Stack>
  )
}

export default GeneralInfo