import React from "react"
import moment from "moment"
import { gql, useQuery } from "@apollo/client"
import { Container, Paper, Stack, Typography, Box, Divider, Tabs, Tab, TableContainer, Button, IconButton } from "@mui/material"
import PrintIcon from '@mui/icons-material/Print';
import { useParams } from "react-router-dom"

import useAuth from "../../../authentication"
import { Loading } from "../../common"
import InternalOfficerInfo from "./InternalOfficerInfo"
import GeneralInfo from "./GeneralInfo"
import Description from "./Description"
import EvaluationInfo from "./EvaluationInfo"
import Images from "./Images"
import Prices from "./Prices"
import RequestInfo from "./RequestInfo"


const EVALUATION = gql`
query($id: ID){
  evaluation(id:$id){
        id
        uuid
        createdDate
        evaluator{
          id
          firstName
          lastName
        }
        date
        approvedDate
        visitDate
        isReadyForApproval
        requestor
        evaluationFor
        cost
        isPaid
        paymentDate
        evaluatorPercentage
        isEvaluatorPaid

        descriptions{
          id
          title
          description
        }

        residentialevaluation{
          id
          estateMap
          estatePicture
          governate
          area{
            id
            nameAr
          }
          block
          street
          avenue
          landArea
          landBlock
          documentNo
          planNo
          paciNo

          regulatoryOpinion
          valuationMethod{
            id
            title
          }
          assetMethod{
            id
            title
          }
          location{
            id
            title
          }
          hasAlley
          hasWideAlley
          streetType{
            id
            title
          }
          curbSize
          isShared
          hasStreetLightPole
          hasBusStop
          isLand
          locationSpecs{
            id
            title
          }
          plotSpecs{
            id
            title
          }
          facing{
            id
            title
          }
          direction
          description
          floors
          buildingAge
          finishingQuality{
            id
            title
          }
          hasBasement
          apartments
          buildingFrontMaterial{
            id
            title
          }
          
          estateValue
          monthlyIncome
          landValue
          estateValueAfterUsage
          
          civilId
          evaluationRequest
          
    }
  }
}
`


const Details = () => {
  const { user } = useAuth()
  let { evaluationId } = useParams()
  const {data, loading} = useQuery(EVALUATION, {variables : {id: evaluationId}})
  const [value, setValue] = React.useState(0);

  if (loading) return <Loading/>

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { evaluation } = data
  const { residentialevaluation } = evaluation
  const isEditable = user.isStaff || !evaluation.isReadyForApproval

  return (
  <Container>
    <Typography variant="h5">
      تقييم عقاري # {evaluation.id}
      {evaluation.approvedDate &&
        <IconButton href={`https://api.awaed-kw.com/evaluation/${evaluation.uuid}/`} target="_blank">
          <PrintIcon/>
        </IconButton>
      }
    </Typography>
    <Typography variant="caption" color={"grey.800"} gutterBottom>{moment(evaluation.createdDate).format("DD-MM-YYYY")}</Typography>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="التقييم العقاري" />
        <Tab label="المعلومات الخاصة بالمكتب"/>
      </Tabs>
    </Box>
    <TableContainer component={Paper}>
      {
        value === 0 ? 
        <Box p={1}>
          <RequestInfo {...evaluation} isEditable={isEditable}/>
          <Divider sx={{ bgcolor: "goldenrod" }}/>
          <Box p={2} mt={2}>
            <GeneralInfo {...residentialevaluation} id={evaluation.id} isEditable={isEditable}/>
            <Divider sx={{ bgcolor: "goldenrod" }}/>
            <EvaluationInfo {...evaluation} {...residentialevaluation} id={evaluation.id} isEditable={isEditable}/>
            <Divider sx={{ bgcolor: "goldenrod", marginY: "24px" }}/>
            <Images {...residentialevaluation} id={evaluation.id} isEditable={isEditable}/>
            <Divider sx={{ bgcolor: "goldenrod", marginY: "24px" }}/>
            <Description {...evaluation} {...residentialevaluation} id={evaluation.id} isEditable={isEditable}/>
            <Divider sx={{ bgcolor: "goldenrod", marginY: "24px" }}/>
            <Prices {...residentialevaluation} id={evaluation.id} isEditable={isEditable}/>
          </Box>
        </Box>
        :
        <Box p={2}>
          <InternalOfficerInfo {...evaluation} {...residentialevaluation} isEditable={isEditable}/>
        </Box>
      }
    </TableContainer>
    <Stack direction={"row"} mt={3} spacing={2}>
      {evaluation.approvedDate && <Button variant="contained" href={`https://api.awaed-kw.com/evaluation/${evaluation.uuid}/`} target="_blank">طباعة</Button>}
    </Stack>
  </Container>
  )
}

export default Details