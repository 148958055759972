import React from "react"
import { Stack, TextField, InputAdornment } from "@mui/material"

import { convertArabicNumbers, IsStringNumber } from "../../common/helpers"


const EstateValue = ({values, setValues}) => {

  const handlechange = ({target}) => {
    const value = convertArabicNumbers(target.value)
    setValues({ ...values, [target.name]:value})
  }

  return (
    <Stack spacing={2}>
      <TextField 
        label="القيمة التقديرية للعقار"
        name="estateValue"
        value={values.estateValue}
        onChange={handlechange}
        error={!IsStringNumber(values.estateValue)}
        fullWidth
        InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                د.ك.
              </InputAdornment>
          ),}}
      />
    <TextField 
        label="الإيجار الشهري"
        name="monthlyIncome"
        value={values.monthlyIncome}
        onChange={handlechange}
        error={!IsStringNumber(values.monthlyIncome)}
        fullWidth
        InputProps={{
            endAdornment: (
            <InputAdornment position="start">
                د.ك.
            </InputAdornment>
        ),}}
    />
    <TextField 
        label="القيمة التقديرية للأرض"
        name="landValue"
        value={values.landValue}
        onChange={handlechange}
        error={!IsStringNumber(values.landValue)}
        fullWidth
        InputProps={{
            endAdornment: (
            <InputAdornment position="start">
                د.ك.
            </InputAdornment>
        ),}}
    />
    <TextField 
        label="القيمة التقديرية للبناء بعد خصم الاستهلاك"
        name="estateValueAfterUsage"
        value={values.estateValueAfterUsage}
        onChange={handlechange}
        error={!IsStringNumber(values.estateValueAfterUsage)}
        fullWidth
        InputProps={{
            endAdornment: (
            <InputAdornment position="start">
                د.ك.
            </InputAdornment>
        ),}}
    />
    </Stack>
  )
}

export default EstateValue