import React from "react"
import { useNavigate } from "react-router-dom";
import { TableCell, TableRow, Typography } from "@mui/material"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { red, yellow } from '@mui/material/colors';

import { ESTATE_TYPES, GOVERNATES } from "../../common/constants"


const EvaluationRow = ({id, date, area, governate, approvedDate, isReadyForApproval, isPaid}) => {
  const realEstateType = "residential"
  const navigate = useNavigate()

  return (
    <TableRow hover onClick={()=>navigate(`/evaluation/${id}`)}>
      <TableCell>
        <Typography fontWeight={"bolder"}>{id}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{date}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{ESTATE_TYPES[realEstateType.toLowerCase()]}</Typography>
      </TableCell>
      <TableCell>
        {area.nameAr} - {GOVERNATES[governate]}
      </TableCell>
      <TableCell align="right">
        {isPaid ? <CheckCircleIcon color="success"/> : <DangerousIcon sx={{ color: red[500] }}/>}
      </TableCell>
      <TableCell align="right">
        {approvedDate ? 
          <CheckCircleIcon color="success"/> : 
          isReadyForApproval ? 
          <CircleNotificationsIcon sx={{ color: yellow[700] }}/> : 
          "-"
        }
      </TableCell>
    </TableRow>
  )
}

export default EvaluationRow