import React, { useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { Typography, Box, Stack, IconButton, Button } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';

import ImagesEdit from "./Edit/ImagesEdit"


const UPDATE_EVALUATION = gql`
mutation($id: ID!, $input: ResidentialEvaluationUpdateInput!){
  updateResidentialEvaluation(id:$id, input:$input){
    evaluation{
      id
      residentialevaluation{
        id
        estateMap
        estatePicture
      }
    }
  }
}
`


const Images = (
  {
    id,
    isEditable,
    estateMap,
    estatePicture
  }) => {

  const [edit, setEdit] = useState(false)
  const [values, setValues] = useState({
    estateMap: null,
    estatePicture: null
  })
  const [ updateEvaluation ] = useMutation(UPDATE_EVALUATION, {
    onCompleted: () => {
      setEdit(false)
      setValues({
        estateMap: null,
        estatePicture: null
      })
    }
  })

  const handleSubmit = (e) => {
    let input = {}
    if (values.estateMap) input = {...input, estateMap: values.estateMap}
    if (values.estatePicture) input = {...input, estatePicture: values.estatePicture}
		updateEvaluation({variables:{id, input}})
	}

  const EditButton = (
    <IconButton size="small" onClick={()=>setEdit(true)}>
      <EditIcon/>
    </IconButton>
  )

  const ActionButtons = (
    <Stack spacing={2} my={2} direction="row">
      <Button onClick={handleSubmit} variant="contained" >حفظ</Button>
      <Button onClick={() => setEdit(false)} size="small" >الغاء</Button>
    </Stack>
  )

  return (
    <Box mt={2}> 
      <Typography variant="subtitle1" fontWeight={"bold"} gutterBottom>
        صور العقار
        {!edit && isEditable && EditButton }
      </Typography>
      {edit ?
        <>
          <ImagesEdit values={values} setValues={setValues}/>
          {ActionButtons}
        </>
      :
      <Stack direction={"row"}>
        <img
          src={estateMap}
          style={{maxWidth:"50%"}}
          alt="estate map location"
        />
        <img
          src={estatePicture}
          style={{maxWidth:"50%"}}
          alt="estate"
        />
      </Stack>}
    </Box>
  )
}

export default Images