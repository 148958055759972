import React, {useState} from "react"
import { Typography, Box, Stack, Divider, IconButton, Button, TextField } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import { gql, useMutation } from "@apollo/client"


const UPDATE_EVALUATION = gql`
mutation($id: ID!, $input: ResidentialEvaluationUpdateInput!){
  updateResidentialEvaluation(id:$id, input:$input){
    evaluation{
      id
      residentialevaluation{
        id
        regulatoryOpinion
        description
        valuationMethod{
          id 
          title
        }
        assetMethod{
          id
          title
        }
      }
    }
  }
}`


const EvaluationInfo = (
  {
    id,
    isEditable,
    regulatoryOpinion,
    description,
    valuationMethod,
    assetMethod
  }) => {

  const [edit, setEdit] = useState([false, false])
  const [values, setValues] = useState({
    regulatoryOpinion,
    description,
    valuationMethodId: valuationMethod.id,
    assetMethodId: assetMethod.id
  })

  const [ updateEvaluation ] = useMutation(UPDATE_EVALUATION, {
    onCompleted: () => setEdit([false, false])
  })

  const handleSubmit = (e) => {
		updateEvaluation({variables:{id, input: {...values}}})
	}

  const handlechange = ({target}) => {
    setValues({ ...values, [target.name]:target.value})
  }

  const EditButton = (index) => {    
    return (
    <IconButton size="small" onClick={()=>setEdit(edit.map((value, idx) => idx===index ))}>
      <EditIcon/>
    </IconButton>)
  }

  const ActionButtons = () => {
    return (
      <Stack spacing={2} my={2} direction="row">
        <Button onClick={handleSubmit} variant="contained" >حفظ</Button>
        <Button onClick={() => setEdit(edit.map(() => false ))} size="small" >الغاء</Button>
      </Stack>
    )
  }

  return (
    <Box mt={2}> 
      <Typography variant="subtitle1" fontWeight={"bold"} gutterBottom> 
       الرأي التنظيمي 
       {!edit[0] && isEditable && EditButton(0)}
      </Typography>
      {edit[0] ? 
        <>
          <TextField
            label="الرأي التنظيمي"
            value={values.regulatoryOpinion}
            onChange={handlechange}
            name="regulatoryOpinion"
            required
            fullWidth
          />
          {ActionButtons()}
        </>
        :
          <Typography>{regulatoryOpinion}</Typography>
      }
      <Typography mt={2} variant="subtitle1" fontWeight={"bold"} gutterBottom>طريقة التقييم</Typography>
      <Typography>{assetMethod.title}</Typography>
      <Typography mt={2} variant="subtitle1" fontWeight={"bold"} gutterBottom>طريقة الاصول و الالتزامات التي يتم تقديمها</Typography>
      <Typography>{valuationMethod.title}</Typography>
      <Divider sx={{ bgcolor: "goldenrod", marginY: "24px" }}/>
      <Typography variant="subtitle1" fontWeight={"bold"} gutterBottom> 
        الوصف العام للعقار
        {!edit[1] && isEditable && EditButton(1)}
      </Typography>
      {edit[1] ? 
        <>
          <TextField 
            label="الوصف العام للعقار"
            name="description"
            value={values.description}
            onChange={handlechange}
            fullWidth
            multiline
            required
            rows={3}
          />
          {ActionButtons()}
        </>
        :
        <Typography>{description}</Typography>
      }
    </Box>
  )
}

export default EvaluationInfo