import React, { createContext, useState, useContext, useEffect } from "react";
import { useApolloClient, gql } from "@apollo/client";

import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";


const ME = gql`
  query {
    me {
      id
      isStaff
    }
  }
`;

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useNavigate();

  const client = useApolloClient();

  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies.get("token");
      if (token) {
        const currentTime = Date.now() / 1000;
        const { exp } = jwt_decode(token);
        if (exp >= currentTime) {
          const {
            data: { me }
          } = await client.query({ query: ME });
          if (me) setUser(me);
        } else {
          history("/login", { replace: true });
          Cookies.remove("token");
          setUser(null);
          client.cache.reset();
        }
      }
      setLoading(false);
    }
    loadUserFromCookies();
  }, []);

  const logout = () => {
    history("/login", { replace: true });
    Cookies.remove("token");
    setUser(null);
    client.resetStore();
  };

  const login = async ({ token }) => {
    Cookies.set("token", token, { expires: 1 });
    const {
      data: { me }
    } = await client.query({ query: ME });

    if (me) setUser(me);
    history("/");
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: !!user, user, loading, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
